import { Flex, Text } from "@mantine/core";

import { BOOK_SERVICE_BODY } from "constants";
import { useTranslation } from "react-i18next";

export default function ServiceBuyingSummary({ reduxstate }) {
  const { t } = useTranslation();

  let bookServiceBody = window.localStorage.getItem(BOOK_SERVICE_BODY) || {};
  bookServiceBody = Object.keys(bookServiceBody).length
    ? JSON.parse(bookServiceBody)
    : {};

  return (
    <div className="section">
      <div className="block">
        <Flex direction="row" justify="center" align="center">
          <Text c="#f64e60" fz="lg" fw={700}>
            {t("payment-details")}
          </Text>
        </Flex>
        <Flex direction="column" gap="xs">
          <Flex direction="row" justify="space-between" align="center">
            <Flex direction="column">
              <Text fw={700}>{reduxstate.serviceData.serviceName}</Text>
              <Text fz="xs">
                {bookServiceBody.duration} {t("minutes")}
              </Text>
            </Flex>
            <Text fw={700}>
              €{(reduxstate.totalPrice - reduxstate.processingFee).toFixed(2)}
            </Text>
          </Flex>
          <Flex direction="row" justify="space-between" align="center">
            <Flex direction="column">
              <Text fw={700}>{t("processing-fee")}</Text>
              <Text fz="xs">
                {reduxstate.processingFeeText.replace(/\+\s*\+/g, "+")}
              </Text>
            </Flex>
            <Text fw={700}>€{reduxstate.processingFee}</Text>
          </Flex>

          <Flex
            direction="row"
            justify="space-between"
            align="center"
            bg="#F4F4F4"
            p={12}
            style={{ borderRadius: 10 }}
          >
            <Text fz="lg">{t("total-vat")}</Text>
            <Text fw={500} fz="lg">
              €{reduxstate.totalPrice}
            </Text>
          </Flex>
        </Flex>
      </div>
    </div>
  );
}
