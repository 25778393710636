import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { confirmAlert } from "react-confirm-alert";
import { Button, Flex, rem, Text } from "@mantine/core";
import { useAtom } from "jotai";

import { ErrorAlert, UserDetails } from "components";
import ServiceInfoCard from "views/TicketServices/ServiceInfoCard";
import ServiceBuyingSummary from "views/TicketServices/ServiceBuyingSummary";
import { ChevronsIconLeft, TikkieIcon } from "assets/icon";

import { serviceStep } from "store/atoms";
import { useCreateTikkiePaymentLinkMutation } from "store/services/ticketServices";
import { updatestate } from "store/reducer";
import { useTranslation } from "react-i18next";

export default function TikkiePayment() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const reduxstate = useSelector((state) => state.State);
  const { t } = useTranslation();

  const [, setServiceStep] = useAtom(serviceStep);

  const [
    generateLink,
    {
      isLoading: isGeneratingLink,
      isSuccess: hasLinkGenerated,
      data: generatedLinkData,
    },
  ] = useCreateTikkiePaymentLinkMutation();
  console.log("generated link data", generatedLinkData);

  const handleGenerateLink = async () => {
    try {
      const { bookServiceBody } = reduxstate;

      const serviceBookingPayload = {
        ...bookServiceBody,
        bookingId: "",
        utcOffset: new Date().getTimezoneOffset(),
        paymentMethod: "tikkie",
      };
      const { data, statusCode } = await generateLink({
        amount: Number(reduxstate?.totalPrice),
        serviceId: reduxstate?.serviceSelected?._id,
        currency: "eur",
        slotDuration: reduxstate?.slotData?.duration,
        priceType: reduxstate?.slotData?.priceType,
        slotPrice: Number(reduxstate?.slotData?.price),
        slotDescription: reduxstate?.slotData?.description,
        isPackage: false,
        serviceBookingPayload,
      }).unwrap();

      if (statusCode !== 200) {
        confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <ErrorAlert
                onClose={() => {
                  onClose();
                  handleChangePaymentMethod();
                }}
                error={t("errors.tikkie-link-gen")}
              />
            );
          },
          closeOnClickOutside: false,
          closeOnEscape: false,
        });

        return;
      }

      dispatch(updatestate({ tikkiePayLink: data?.url }));
      window.open(data?.url, "_blank")?.focus();

      navigate(`/tikkie-pay-service-check/${data?.paymentRequestToken}`);
    } catch (error) {
      console.log(error);

      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <ErrorAlert
              onClose={() => {
                onClose();
                handleChangePaymentMethod();
              }}
              error={t("errors.tikkie-link-gen")}
              isServerError={true}
            />
          );
        },
        closeOnClickOutside: false,
        closeOnEscape: false,
      });
    }
  };

  const handleChangePaymentMethod = () => {
    setServiceStep(4);
    navigate(-1, { replace: true });
  };

  return (
    <div className="ticket-services">
      <div className="wrapper">
        <div className="page-hdr">
          <button className="back-btn" onClick={handleChangePaymentMethod}>
            <ChevronsIconLeft />
            <div className="lbl">{t("back")}</div>
          </button>
          <div className="title">{t("review-and-pay")}</div>
        </div>
        <div className="content">
          <Flex direction="column">
            <ServiceInfoCard
              serviceData={reduxstate.serviceData}
              resource={reduxstate.resourceDetail}
              isReadMore={false}
              selectedStep={5}
              toggleReadMore={() => {}}
              when={{
                day: reduxstate?.bookServiceBody?.slot_date,
                start: reduxstate?.bookServiceBody?.slot_start_time,
                end: reduxstate?.bookServiceBody?.slot_end_time,
              }}
              where={reduxstate.serviceSelectedLocation}
              slotData={reduxstate.slotData}
            />
            <UserDetails />
            <ServiceBuyingSummary reduxstate={reduxstate} />
            <div className="section">
              <div className="block">
                <Flex direction="row" justify="space-between" align="center">
                  <Flex direction="row" gap="sm" align="center">
                    <Flex w={rem(36)} h={rem(36)}>
                      <TikkieIcon />
                    </Flex>
                    <Text fw={600} fz="lg">
                      {t("tikkie")}
                    </Text>
                  </Flex>
                  <Button
                    variant="subtle"
                    onClick={handleChangePaymentMethod}
                    fz="md"
                    c="#584CF4"
                  >
                    {t("change")}
                  </Button>
                </Flex>
              </div>
            </div>
            <Button
              fullWidth
              radius={10}
              bg="#f64e60"
              size="lg"
              fz="md"
              onClick={handleGenerateLink}
              sx={{
                "&:hover": {
                  backgroundColor: "#f64e60ee",
                  transition: "background-color 0.2s ease-in-out",
                },
              }}
              loading={isGeneratingLink || hasLinkGenerated}
            >
              {t("pay-and-confirm")}
            </Button>
          </Flex>
        </div>
      </div>
    </div>
  );
}
