import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { useGetTicketsInfoQuery } from "store/services/ticketServices";
import { LocationIcon, TimerIcon, ShareIcon, CheckIcon } from "assets/icon";
import moment from "moment";
import { Loader } from "components";
import { useSelector, useDispatch } from "react-redux";
import useStyles from "views/TicketServices/styles";
import { Button, Flex, Text, rem } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { useAtom } from "jotai";
import { step } from "store/atoms";
import { updatestate } from "store/reducer";
import NEW_BOOKING_URL from "utils/getNewBookingURL";
import EventInfoCard from "views/TicketBooking/EventInfoCard";
import { useTranslation } from "react-i18next";

function OrderConfirmation({
  setBookingConfirmed,
  bookingconfirmed,
  totalPrice,
  reduxstate,
}) {
  const id = window.localStorage.getItem("ticksID") || "";
  const { data = [], isFetching } = useGetTicketsInfoQuery(id);
  const [totalServiceFee, setTotalServiceFee] = useState(0);
  const { classes } = useStyles();
  const navigate = useNavigate();
  const [, setEventSelectedStep] = useAtom(step);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    let fee = 0;
    if (
      bookingconfirmed?.ticketsBought &&
      bookingconfirmed.ticketsBought.length
    ) {
      bookingconfirmed?.ticketsBought.forEach((item) => {
        if (item.absorbServiceFee) return;

        fee = fee + Number(item.serviceFee);
      });
      setTotalServiceFee(fee);
    }
  }, [bookingconfirmed]);

  const handleRestart = useCallback(() => {
    // clear booking reservation and processing fee
    dispatch(updatestate({ reservationId: "", processingFee: 0 }));

    const paymentMethod = bookingconfirmed?.paymentMethod;

    const isStripeOrTikkiePayment =
      paymentMethod === "tikkie" ||
      paymentMethod === "stripe" ||
      paymentMethod === "googlePay" ||
      paymentMethod === "applePay" ||
      paymentMethod === "ideal";

    if (isStripeOrTikkiePayment) {
      const eventName = reduxstate.eventData.className
        .replace(/\s+/g, "-")
        .toLowerCase();

      navigate(`/booking/${eventName}`, { replace: true });
    } else {
      setEventSelectedStep(1);
      setBookingConfirmed(false);
    }
  }, [
    bookingconfirmed?.paymentMethod,
    dispatch,
    navigate,
    reduxstate?.eventData?.className,
    setBookingConfirmed,
    setEventSelectedStep,
  ]);

  if (isFetching) {
    return (
      <div className="ticket-view-loading">
        <Loader size={40} />
      </div>
    );
  }

  return (
    <div className="ticket-booking order-confirmation">
      <div className="wrapper">
        <div className="page-hdr">
          <div className="title">{t("order-confirmation")}</div>
        </div>
        <div className="content">
          {/* Ticket Info*/}
          <EventInfoCard
            reduxstate={reduxstate}
            selectedStep={5}
            isReadMore={false}
            toggleReadMore={() => {}}
          />
          <div className="section payment-due">
            {bookingconfirmed?.amount === 0 ? (
              <div className="title-free">Free Event</div>
            ) : bookingconfirmed?.paymentStatus == "Unpaid" ? (
              <div className="title">Payments Due</div>
            ) : (
              <div className="title">
                {t("payment-confirmed")}
                <div className="icon">
                  <CheckIcon />
                </div>
              </div>
            )}
            <div className="ticks">
              {bookingconfirmed?.ticketsBought?.map((item, index) => {
                // const absorbServiceFee = item?.absorbServiceFee || false;

                return (
                  <div key={index} className="item">
                    <div className="lit">
                      <div className="lbl">{item?.ticketType}</div>
                      {/* <div className="price">
                        {`€${item.ticketAmount} + ${
                          !absorbServiceFee
                            ? `€${(
                                Number(item.serviceFee).toFixed(2) /
                                Number(item.bookingQty)
                              ).toFixed(2)}`
                            : "including service"
                        } fee.`}
                      </div> */}
                    </div>
                    <div className="rit">
                      <div className="qty">
                        {item?.bookingQty} X €
                        {parseFloat(item.ticketAmount) +
                          (item.absorbServiceFee
                            ? 0
                            : parseFloat(
                                parseFloat(item.serviceFee) /
                                  parseFloat(item.bookingQty)
                              ))}{" "}
                      </div>
                      <div className="price">{item?.totalPrice.toFixed(2)}</div>
                    </div>
                  </div>
                );
              })}
              <Flex justify="space-between" align="center" direction="row">
                <Text fz={rem(15)} fw="600">
                  Processing fee
                </Text>
                <Text fz={rem(15)} fw="600">
                  €{reduxstate.processingFee}
                </Text>
              </Flex>
            </div>
            <div className="total">
              <div className="text">{`Total (incl. tax & €${reduxstate.processingFee} processing Fee)`}</div>
              <div className="price">€{bookingconfirmed.amount.toFixed(2)}</div>
            </div>
            {bookingconfirmed?.paymentStatus == "Unpaid" && (
              <div className="notice">
                Host may send you a payment request before start of the event
              </div>
            )}
          </div>
          <div className="section success-blk">
            <img src="/images/success.svg" className="vector" />
            <div className="label">{t("order-confirmed")}</div>
            <div className="text">{t("booking-details-sent-to")}</div>
            <div className="address">{reduxstate.email}</div>
          </div>
          <div className="section block">
            <Button
              className={classes.submit}
              variant="filled"
              fullWidth
              // onClick={handleRestart}
              component="a"
              href={`${NEW_BOOKING_URL}/${id}`}
              target="_blank"
              radius={10}
            >
              Book Again
            </Button>
          </div>
          {/* <div className="section share-blk">
            <button className="button-primary">
              Share <ShareIcon />
            </button>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default OrderConfirmation;
