import axios from "axios";
import moment from "moment";
import { confirmAlert } from "react-confirm-alert";

import store from "../store";
import { updatestate } from "store/reducer";
import {
  ADD_PARTICIPANT_BODY,
  SEND_EMAIL_CONFIRMATION_DATA,
  TOTAL_PRICE,
  BASE_URL,
} from "../constants";

import {
  ErrorAlert,
  PrivateCodeConfirmingAlert,
  ValidPrivateCodeAlert,
  InvalidPrivateCodeAlert,
} from "components";

const TEMP_TOKEN =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY1NmVlZDZlMWEwOGVjODAxMjE0OGRkMiIsInRva2VuVGltZSI6IjIwMjMtMTItMjRUMTA6NTE6MTEuNjczWiIsInVzZXJPYmoiOnsiX2lkIjoiNjU2ZWVkNmUxYTA4ZWM4MDEyMTQ4ZGQyIiwidXNlcm5hbWUiOiIrMzExMjM0NTY3OCIsImNvdW50cnlDb2RlIjoiIiwicGhvbmVOdW1iZXIiOiIxMjM0NTY3OCIsImdlbmRlciI6Ik1hbGUiLCJpc1ZlcmlmaWVkIjp0cnVlLCJzb2NpYWxUeXBlIjoibm9ybWFsIiwiZmF2b3JpdGVFdmVudExpc3QiOltdLCJ1c2VyVHlwZSI6InN0dWRlbnQiLCJpc0d1ZXN0VXNlciI6ZmFsc2UsImlzVGVhY2hlclNldHVwIjpmYWxzZSwiaXNTdHVkZW50U2V0dXAiOnRydWUsImRldmljZVR5cGUiOiJBbmRyb2lkIiwiaXNEZWxldGVkIjpmYWxzZSwiaXNBY3RpdmUiOnRydWUsInVzZXJTdGF0dXMiOiJ1bmxvY2tlZCIsInNlbGV0ZWRDYXRlZ29yaWVzIjpbXSwiYXBwVG9rZW4iOiJhOTgzODYzOC01ODU0LTRjOGEtOTBhYi1iNTJjYWI1ZGE0M2QiLCJvbmVzaWduYWxUb2tlbiI6WyJhOTgzODYzOC01ODU0LTRjOGEtOTBhYi1iNTJjYWI1ZGE0M2QiXSwiaXNTdWJzY3JpcHRpb25BY3RpdmUiOnRydWUsImZyZWVUaWVyUXVvdGEiOjUwLCJjcmVhdGVkQXQiOiIyMDIzLTEyLTA1VDA5OjI5OjE2Ljk2N1oiLCJmYW1pbHlNZW1iZXIiOltdLCJjdXN0b21lcklkIjoiQ09OLTEwODUiLCJkb2IiOiIyMDAwLTEyLTA0VDE5OjAwOjAwLjAwMFoiLCJlbWFpbCI6ImNydXN0QGJyZWFkLmNvbSIsImZ1bGxOYW1lIjoiQ3J1c3QiLCJwcm9maWxlUGljIjpudWxsLCJpZCI6IjY1NmVlZDZlMWEwOGVjODAxMjE0OGRkMiJ9LCJpYXQiOjE3MDM0MTUwNzEsImV4cCI6MTcwNDAxOTg3MX0.OdaujGP7zSyfPaflgzyuFfWitcmdXrOPkj9KDtcUZz4";

/**
 * @param {string} endpoint
 * @param {RawAxiosRequestConfig<any>} axiosConfig
 * @param {{ showErrorPopup: boolean }} showErrorPopup
 * @returns {Promise<[boolean, any]>} request
 */
const baseQuery = async (endpoint, axiosConfig, { showErrorPopup }) => {
  console.log("baseQuery", endpoint, axiosConfig);

  const request = await axios(axiosConfig)
    .then((res) => {
      console.log(endpoint, "response", res.data);

      if (res.data.statusCode === 200) {
        return [true, res.data.data];
      } else {
        if (showErrorPopup) {
          confirmAlert({
            customUI: ({ onClose }) => (
              <ErrorAlert
                onClose={onClose}
                error={
                  typeof res.data?.message !== "object"
                    ? res.data?.message
                    : JSON.stringify(res.data?.message)
                }
                isServerError={true}
              />
            ),
            closeOnEscape: false,
            closeOnClickOutside: false,
          });
        }

        return [false, res.data];
      }
    })
    .catch((error) => {
      confirmAlert({
        customUI: ({ onClose }) => (
          <ErrorAlert
            onClose={onClose}
            error={
              typeof error?.response?.message !== "object"
                ? error?.response?.message
                : JSON.stringify(error?.response?.message)
            }
            isServerError={true}
          />
        ),
        closeOnEscape: false,
        closeOnClickOutside: false,
      });

      return [false, error.response];
    });

  return request;
};

export const sendOtpToAddParticipant = async (
  ticketFields,
  setLoading,
  setver_token,
  reduxstate
) => {
  setLoading(true);
  console.log("ticketFields", reduxstate);
  console.log("send otp to add participant payload", {
    scheduleClassId: reduxstate.scheduleId,
    isPackage: false,
    username: `+${ticketFields.username}`,
    fullName: ticketFields.fullName,
  });
  try {
    const config = {
      method: "post",
      url: BASE_URL + "/users/sendOtpToAddParticipant",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        scheduleClassId: reduxstate.scheduleId,
        isPackage: false,
        username: `+${ticketFields.username}`,
        fullName: ticketFields.fullName,
      },
    };
    axios(config)
      .then(function (response) {
        console.log("response........", response.data);
        setLoading(false);
        setver_token(response.data?.data?.token);
        //setSelectedStep(1);
      })
      .catch(function (error) {
        console.log(error);
        confirmAlert({
          customUI: ({ onClose }) => (
            <ErrorAlert
              onClose={onClose}
              error={error.message}
              isServerError={true}
            />
          ),
          closeOnEscape: false,
          closeOnClickOutside: false,
        });
        setLoading(false);
      });
  } catch (error) {
    console.log("error....", error);
    confirmAlert({
      customUI: ({ onClose }) => (
        <ErrorAlert
          onClose={onClose}
          error={error.message}
          isServerError={true}
        />
      ),
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
    setLoading(false);
  }
};

export const verifyOtpToAddParticipant = async ({
  code,
  setLoading,
  id,
  ver_token,
  setPhoneVerified,
  setbookingconfirmed,
  addParticipantData,
}) => {
  setLoading(true);
  console.log("ver_code", {
    code: code,
    token: ver_token,
  });
  try {
    const config = {
      method: "post",
      url: BASE_URL + "/users/verifyOtpToAddParticipant",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        code,
        token: ver_token,
      },
    };
    axios(config)
      .then(function (response) {
        console.log("response...", response.data);
        setLoading(false);
        if (response?.data?.data?.userId) {
          setPhoneVerified(true);

          setTimeout(() => {
            addParticipant(addParticipantData);
          }, 3000);
        }
      })
      .catch(function (error) {
        console.log(error);
        confirmAlert({
          customUI: ({ onClose }) => (
            <ErrorAlert
              onClose={onClose}
              error={error.message}
              isServerError={true}
            />
          ),
          closeOnEscape: false,
          closeOnClickOutside: false,
        });
        setLoading(false);
      });
  } catch (error) {
    console.log("error................", error);
    confirmAlert({
      customUI: ({ onClose }) => (
        <ErrorAlert
          onClose={onClose}
          error={error.message}
          isServerError={true}
        />
      ),
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
    setLoading(false);
  }
};

export const checkLinkPaymentStatus = async ({
  paymentRequestToken,
  id,
  setLoading,
  setbookingconfirmed,
  ticketFields,
  reduxstate,
  totalPrice,
  setopenTikkiePaymentModal,
  classPhoto,
  eventOrganizer,
  selectedDate,
  isPrivate,
  bookingCode,
  bookingsReservationId,
}) => {
  let eventDetails = {
    classPhoto,
    eventOrganizer,
    selectedDate,
  };

  let val = localStorage.getItem("eventDetails");

  if (val && !classPhoto) {
    eventDetails = JSON.parse(val);
  } else {
    localStorage.setItem("eventDetails", JSON.stringify(eventDetails));
  }

  //setLoading(true);
  console.log("checkLinkPaymentStatus_dataaa", {
    data: {
      paymentRequestToken,
      reduxstate,
    },
  });
  try {
    const config = {
      method: "post",
      url: BASE_URL + "/bookings/payments/checkLinkPaymentStatus",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        paymentRequestToken,
      },
    };
    axios(config)
      .then((response) => {
        console.log("response...", response.data);
        if (response?.data?.data?.numberOfPayments < 1) {
          setTimeout(() => {
            checkLinkPaymentStatus({
              paymentRequestToken,
              id,
              setLoading,
              setbookingconfirmed,
              ticketFields,
              reduxstate,
              totalPrice,
              setopenTikkiePaymentModal,
              classPhoto,
              eventOrganizer,
              selectedDate,
              isPrivate,
              bookingCode,
            });
          }, 2000);
        } else {
          window.localStorage.removeItem(ADD_PARTICIPANT_BODY);
          window.localStorage.removeItem(SEND_EMAIL_CONFIRMATION_DATA);
          window.localStorage.removeItem(TOTAL_PRICE);

          //setbookingconfirmed(true)
          addParticipant(
            // ticketFields,
            // reduxstate,
            // null,
            // setbookingconfirmed,
            // true,
            // totalPrice,
            // setopenTikkiePaymentModal,
            // eventDetails
            {
              ticketFields,
              reduxstate,
              setLoading: null,
              setbookingconfirmed,
              paid: true,
              totalPrice,
              setopenTikkiePaymentModal,
              eventDetails,
              isPrivate,
              bookingCode,
              bookingsReservationId,
            }
          );
          //history.push(`order-confirmation/${id}`)
          //window.open(`${window.location.origin}/order-confirmation/${id}`,"_self");
        }
      })
      .catch(function (error) {
        console.log(error);
        confirmAlert({
          customUI: ({ onClose }) => (
            <ErrorAlert
              onClose={onClose}
              error={error.message}
              isServerError={true}
            />
          ),
          closeOnEscape: false,
          closeOnClickOutside: false,
        });
      });
  } catch (error) {
    console.log("error....", error);
    confirmAlert({
      customUI: ({ onClose }) => (
        <ErrorAlert
          onClose={onClose}
          error={error.message}
          isServerError={true}
        />
      ),
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  }
};

export const createPaymentLink = async (
  ticketFields,
  setLoading,
  reduxstate,
  totalPrice,
  id,
  setbookingconfirmed,
  setopen_in_new_tab,
  setopenTikkiePaymentModal
) => {
  //setLoading(true);
  const selectedEventPricing = ticketFields.selectedEventPricing.filter(
    (ticket) => ticket.bookingQty > 0
  );

  console.log("dataaa", {
    data: {
      amount: totalPrice,
      scheduleId: reduxstate.id,
      isPackage: false,
      currency: "eur",
      selectedEventPricing,
    },
  });
  try {
    const config = {
      method: "post",
      url: BASE_URL + "/bookings/payments/createPaymentLinkUA",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        amount: totalPrice,
        scheduleId: reduxstate.id,
        isPackage: false,
        currency: "eur",
        selectedEventPricing,
      },
    };
    let response = await axios(config);
    return response?.data;
  } catch (error) {
    console.log("error....", error);
    confirmAlert({
      customUI: ({ onClose }) => (
        <ErrorAlert
          onClose={onClose}
          error={error.message}
          isServerError={true}
        />
      ),
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  }
};

export const addParticipant = async ({
  ticketFields,
  reduxstate,
  setLoading,
  setbookingconfirmed,
  paid,
  totalPrice,
  setopenTikkiePaymentModal,
  classPhoto,
  eventOrganizer,
  selectedDate,
  isPrivate,
  bookingCode,
  bookingsReservationId,
  paymentStatus,
  paymentMethod,
  paymentMode,
}) => {
  let eventDetails = {
    classPhoto,
    eventOrganizer,
    selectedDate,
  };

  const val = localStorage.getItem("eventDetails");

  if (val && !classPhoto) {
    eventDetails = JSON.parse(val);
  } else {
    localStorage.setItem("eventDetails", JSON.stringify(eventDetails));
  }

  console.log("AddParticipant-Event Details", eventDetails);

  if (setLoading) {
    setLoading(true);
  }
  const mod_ticketFields = [];
  ticketFields.selectedEventPricing?.map((item) => {
    if (item?.bookingQty > 0) {
      mod_ticketFields.push({
        bookingQty: item?.bookingQty,
        totalPrice: item?.totalPrice,
        ticketType: item?.ticketType,
        serviceFee: !item?.absorbServiceFee
          ? Number(item?.serviceFee) * item?.bookingQty
          : 0,
        ticketAmount: Number(item.amount),
      });
    }
  });
  const ticketsObject = mod_ticketFields.reduce(
    (acc, ticket, idx) => ({
      ...acc,
      [`bookingQty${idx + 1}`]: ticket.bookingQty,
      [`ticketType${idx + 1}`]: ticket.ticketType,
      [`ticketTotalPrice${idx + 1}`]: ticket.totalPrice,
    }),
    {}
  );
  const totalFees = mod_ticketFields.reduce(
    (acc, ticket) => acc + parseFloat(ticket.serviceFee),
    0
  );

  console.log("AddParticipant-Event Details", eventDetails);

  const body = {
    scheduleClassId: reduxstate.id,
    type: "add",
    isPackage: false,
    isEvent: reduxstate.eventData.isEvent,
    username: `+${ticketFields.username}`,
    fullName: ticketFields.fullName,
    email: ticketFields.email,
    isPaid: paid ? true : false,
    selectedEventPricing: mod_ticketFields,
    bookingId: ticketFields.referenceId,
    ...(isPrivate && { bookingCode }),
    bookingsReservationId,
    paymentStatus,
    paymentMethod,
    paymentMode,
  };

  console.log("addParticipant-data", body);

  try {
    const config = {
      method: "post",
      url: BASE_URL + "/bookings/addClassBooking",
      headers: {
        "Content-Type": "application/json",
      },
      data: body,
    };
    axios(config)
      .then(async function (response) {
        console.log("add participant response...", response.data);

        if (response.data.statusCode !== 200) {
          console.log("error addParticipant", response.data);
          confirmAlert({
            customUI: ({ onClose }) => (
              <ErrorAlert
                onClose={onClose}
                error={response?.data?.message}
                isServerError={true}
              />
            ),
            closeOnEscape: false,
            closeOnClickOutside: false,
          });
          if (setLoading) {
            setLoading(false);
          }

          return;
        }

        store.dispatch(updatestate({ reservationId: "" }));
        await SendEmailtoParticipant(
          ticketFields,
          reduxstate,
          response.data?.data,
          totalPrice,
          setbookingconfirmed,
          setLoading,
          setopenTikkiePaymentModal,
          ticketsObject,
          totalFees,
          eventDetails
        );
        //setSelectedStep(1);
      })
      .catch(function (error) {
        console.log(error);
        confirmAlert({
          customUI: ({ onClose }) => (
            <ErrorAlert
              onClose={onClose}
              error={error.message}
              isServerError={true}
            />
          ),
          closeOnEscape: false,
          closeOnClickOutside: false,
        });
        if (setLoading) {
          setLoading(false);
        }
      });
  } catch (error) {
    console.log("error....", error);
    confirmAlert({
      customUI: ({ onClose }) => (
        <ErrorAlert
          onClose={onClose}
          error={error.message}
          isServerError={true}
        />
      ),
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
    if (setLoading) {
      setLoading(false);
    }
  }
};

export const SendEmailtoParticipant = async (
  ticketFields,
  reduxstate,
  addParticipantresponse,
  totalPrice,
  setbookingconfirmed,
  setLoading,
  setopenTikkiePaymentModal,
  ticketsObject,
  totalFees,
  eventDetails
) => {
  //setLoading(true);
  console.log("SendEmailtoParticipant-data", {
    name: ticketFields.username,
    toEmail: ticketFields.email,
    variables: {
      eventPhoto: eventDetails.classPhoto,
      eventName: addParticipantresponse.className,
      eventDate: `${moment(eventDetails.selectedDate).format("dddd, MMMM Do")}`,
      eventTime: `${moment(reduxstate?.classDate).format("h:mm A")} - ${moment(
        reduxstate?.classEndDate
      ).format("h:mm A")}`,
      eventLocation: reduxstate.location.addressName,
      eventAddress: reduxstate.location.address,
      eventOrganizer: eventDetails.eventOrganizer,
      ...ticketsObject,
      serviceFee: totalFees,
      totalPayableAmount: addParticipantresponse.amount,
      paymentMethod: addParticipantresponse.paymentMethod,
      bookingId: addParticipantresponse?.bookingId,
      paymentStatus: addParticipantresponse.paymentStatus,
    },
  });
  console.log("SendEmailtoParticipant-Event Details", eventDetails);
  console.log("SendEmailtoParticipant-Tickets Object", ticketsObject);
  console.log("SendEmailtoParticipant-Total Fees", totalFees);
  console.log("addParticipantresponse", addParticipantresponse);
  console.log("ticketFields-bb", ticketFields);
  try {
    const config = {
      method: "post",
      url: BASE_URL + "/bookings/sendBookingConfirmation",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        toName: ticketFields.fullName,
        toEmail: ticketFields.email,
        bookingType: "event",
        fromName: addParticipantresponse.bookingInfo.businessName,
        variables: {
          eventPhoto: eventDetails.classPhoto,
          eventName: addParticipantresponse.className,
          eventDate: `${moment(eventDetails.selectedDate).format(
            "dddd, MMMM Do"
          )}`,
          eventTime: `${moment(reduxstate?.classDate).format(
            "h:mm A"
          )} - ${moment(reduxstate?.classEndDate).format("h:mm A")}`,
          eventLocation: reduxstate.location.addressName,
          eventAddress: reduxstate.location.address,
          eventOrganizer: eventDetails.eventOrganizer,
          ...ticketsObject,
          serviceFee: totalFees,
          totalPayableAmount: addParticipantresponse.bookingInfo.amount,
          paymentMethod: addParticipantresponse.bookingInfo.paymentMethod,
          bookingId: addParticipantresponse?.bookingInfo.bookingId,
          paymentStatus: addParticipantresponse.bookingInfo.paymentStatus,
          manageBookingUrl: addParticipantresponse.bookingInfo.manageBookingUrl,
        },
        //fromEmail: "info@bookzy.nl",
        //name: ticketFields.fullName,
        // bookingId: addParticipantresponse?.bookingId,
        // bookingDate: moment(new Date()).format("MMM Do YY, h:mm"),
        // totalPrice: totalPrice,
      },
    };
    axios(config)
      .then(function (response) {
        console.log("SendEmailtoParticipant_response...", response.data);
        // toast(`${response?.data?.message} ${ticketFields.email}`, {
        //   type: "success",
        // });
        //
        setbookingconfirmed(addParticipantresponse);
        if (setopenTikkiePaymentModal) {
          setopenTikkiePaymentModal(false);
        }
        if (setLoading) {
          setLoading(false);
        }
        //setSelectedStep(1);
      })
      .catch(function (error) {
        console.log(error);
        confirmAlert({
          customUI: ({ onClose }) => (
            <ErrorAlert
              onClose={onClose}
              error={error.message}
              isServerError={true}
            />
          ),
          closeOnEscape: false,
          closeOnClickOutside: false,
        });
        //setLoading(false);
      });
  } catch (error) {
    console.log("error....", error);
    confirmAlert({
      customUI: ({ onClose }) => (
        <ErrorAlert
          onClose={onClose}
          error={error.message}
          isServerError={true}
        />
      ),
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
    //setLoading(false);
  }
};

export const verifyPrivateBookingCode = async ({
  code,
  classId,
  setIsVerified,
  setBookingCode,
  continueStep,
}) => {
  const data = {
    inviteCode: code,
    classId,
  };

  console.log("verifyPrivateBookingCode-dataa", data);

  try {
    confirmAlert({
      customUI: () => PrivateCodeConfirmingAlert(),
      closeOnEscape: false,
      closeOnClickOutside: false,
    });

    const config = {
      method: "put",
      // url: "https://bmcapi-dev.bookzy.app/dev/schools/verifyInviteCode",
      url: BASE_URL + "/dev/schools/verifyInviteCode",
      headers: {
        "Content-Type": "application/json",
      },
      data,
    };
    axios(config)
      .then(function (response) {
        console.log("verifyPrivateBookingCode-response", response.data);

        if (response.data.statusCode === 403) {
          confirmAlert({
            customUI: ({ onClose }) => InvalidPrivateCodeAlert({ onClose }),
            closeOnEscape: false,
            closeOnClickOutside: false,
          });
        } else if (response.data.statusCode === 200) {
          setIsVerified(true);
          setBookingCode(code);
          confirmAlert({
            customUI: ({ onClose }) => ValidPrivateCodeAlert({ onClose }),
            closeOnEscape: false,
            closeOnClickOutside: false,
          });

          setTimeout(() => continueStep(true), 1000);
        }
      })
      .catch(function (error) {
        console.log("error...", error);
        confirmAlert({
          customUI: ({ onClose }) => (
            <ErrorAlert
              onClose={onClose}
              error={error.message}
              isServerError={true}
            />
          ),
          closeOnEscape: false,
          closeOnClickOutside: false,
        });
      });
  } catch (error) {
    console.log("error...", error);
    confirmAlert({
      customUI: ({ onClose }) => (
        <ErrorAlert
          onClose={onClose}
          error={error.message}
          isServerError={true}
        />
      ),
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  }
};

export const createBookingReservation = async ({
  scheduleClassId,
  selectedEventPricing,
  prevTickets,
}) => {
  const data = {
    scheduleClassId,
    selectedEventPricing,
  };

  console.log("createBookingReservation-dataa", data);

  const config = {
    method: "post",
    url: BASE_URL + "/bookings/saveClassReservations", //save reservation for classes
    headers: {
      "Content-Type": "application/json",
    },
    data,
  };

  const request = await axios(config)
    .then(function (response) {
      console.log("saveReservation-response", response.data);

      if (response.data.statusCode === 200) {
        prevTickets.current = selectedEventPricing;

        return [true, response.data.data];
      }

      if (response.data.statusCode !== 200) {
        confirmAlert({
          customUI: ({ onClose }) => (
            <ErrorAlert
              onClose={onClose}
              error={
                response.data?.message?.message
                  ? response.data?.message?.message
                  : response.data?.message
              }
              isServerError={true}
            />
          ),
          closeOnEscape: false,
          closeOnClickOutside: false,
        });

        return [false, response];
      }
    })
    .catch(function (error) {
      confirmAlert({
        customUI: ({ onClose }) => (
          <ErrorAlert
            onClose={onClose}
            error={error.message}
            isServerError={true}
          />
        ),
        closeOnEscape: false,
        closeOnClickOutside: false,
      });

      return [false, error];
    });

  return request;
};

export const updateBookingReservation = async ({
  reservationId,
  selectedEventPricing,
  prevTickets,
}) => {
  const data = {
    bookingsReservationId: reservationId,
    selectedEventPricing,
  };

  console.log("updateBookingReservation-dataa", data);

  const config = {
    method: "post",
    url: BASE_URL + "/bookings/updateClassReservations",
    headers: {
      "Content-Type": "application/json",
    },
    data,
  };

  const request = await axios(config)
    .then(function (response) {
      console.log("updateReservation-response", response.data);

      if (response.data.statusCode === 200) {
        prevTickets.current = selectedEventPricing;

        return [true, response.data.data];
      }

      if (response.data.statusCode !== 200) {
        confirmAlert({
          customUI: ({ onClose }) => (
            <ErrorAlert
              onClose={onClose}
              error={
                response.data?.message?.message
                  ? response.data?.message?.message
                  : response.data?.message
              }
              isServerError={true}
            />
          ),
          closeOnEscape: false,
          closeOnClickOutside: false,
        });

        return [false, response];
      }
    })
    .catch(function (error) {
      confirmAlert({
        customUI: ({ onClose }) => (
          <ErrorAlert
            onClose={onClose}
            error={error.message}
            isServerError={true}
          />
        ),
        closeOnEscape: false,
        closeOnClickOutside: false,
      });

      return [false, error];
    });

  return request;
};

export const addParticipantToEvent = async (body) => {
  const mod_ticketFields = [];
  body.ticketFields.selectedEventPricing?.map((item) => {
    if (item?.bookingQty > 0) {
      mod_ticketFields.push({
        bookingQty: item?.bookingQty,
        totalPrice: item?.totalPrice,
        ticketType: item?.ticketType,
        serviceFee: item?.serviceFee,
        ticketAmount: Number(item.amount),
      });
    }
  });

  const data = {
    scheduleClassId: body.scheduleClassId,
    type: "add",
    isPackage: false,
    isEvent: body.isEvent,
    username: `+${body.ticketFields.username}`,
    fullName: body.ticketFields.fullName,
    email: body.ticketFields.email,
    isPaid: true,
    selectedEventPricing: mod_ticketFields,
    // bookingId: body.ticketFields.referenceId,
    bookingId: body.bookingId,
    ...(body.isPrivate && { bookingCode: body.bookingCode }),
    bookingsReservationId: body.bookingsReservationId,
    paymentMethod: body.paymentMethod,
    paymenthMode: body.paymentMode,
    paymentStatus: body.paymentStatus,
  };

  const config = {
    method: "post",
    url: BASE_URL + "/bookings/addClassBooking",
    headers: {
      "Content-Type": "application/json",
    },
    data,
  };

  const request = await baseQuery("addParticipant", config, {
    showErrorPopup: true,
  });

  return request;
};

export const sendConfirmationEmail = async (body) => {
  const { ticketFields, eventDetails, addParticipantresponse, reduxstate } =
    body;

  const mod_ticketFields = [];
  ticketFields.selectedEventPricing?.map((item) => {
    if (item?.bookingQty > 0) {
      mod_ticketFields.push({
        bookingQty: item?.bookingQty,
        totalPrice: item?.totalPrice,
        ticketType: item?.ticketType,
        serviceFee: item?.serviceFee,
        ticketAmount: Number(item.amount),
      });
    }
  });
  const ticketsObject = mod_ticketFields.reduce(
    (acc, ticket, idx) => ({
      ...acc,
      [`bookingQty${idx + 1}`]: ticket.bookingQty,
      [`ticketType${idx + 1}`]: ticket.ticketType,
      [`ticketTotalPrice${idx + 1}`]: ticket.totalPrice,
    }),
    {}
  );
  const totalFees = mod_ticketFields.reduce(
    (acc, ticket) => acc + parseFloat(ticket.serviceFee),
    0
  );
  const data = {
    toName: ticketFields.fullName,
    toEmail: ticketFields.email,
    bookingType: "event",
    fromName: addParticipantresponse.bookingInfo.businessName,
    variables: {
      eventPhoto: eventDetails.classPhoto,
      eventName: addParticipantresponse.bookingInfo.className,
      eventDate: `${moment(eventDetails.selectedDate).format("dddd, MMMM Do")}`,
      eventTime: `${moment(reduxstate?.classDate).format("h:mm A")} - ${moment(
        reduxstate?.classEndDate
      ).format("h:mm A")}`,
      eventLocation: reduxstate.location.addressName,
      eventAddress: reduxstate.location.address,
      eventOrganizer: eventDetails.eventOrganizer,
      ...ticketsObject,
      serviceFee: totalFees,
      totalPayableAmount: addParticipantresponse.bookingInfo.amount,
      paymentMethod: addParticipantresponse.bookingInfo.paymentMethod,
      bookingId: addParticipantresponse?.bookingInfo.bookingId,
      paymentStatus: addParticipantresponse.bookingInfo.paymentStatus,
      manageBookingUrl: addParticipantresponse.bookingInfo.manageBookingUrl,
    },
  };

  const config = {
    method: "post",
    url: BASE_URL + "/bookings/sendBookingConfirmation",
    headers: {
      "Content-Type": "application/json",
    },
    data,
  };

  const request = await baseQuery("sendBookingConfirmation", config, {
    showErrorPopup: true,
  });

  return request;
};

export const sendServiceConfirmationEmail = async (body) => {
  const { reduxstate, addServiceBookingResponse, notificationType } = body;
  const userName = window.localStorage.getItem("@user-fullName");
  const userEmail = window.localStorage.getItem("@user-email");

  const resourceDetail =
    "resource_detail" in addServiceBookingResponse
      ? addServiceBookingResponse.resource_detail
      : addServiceBookingResponse.resourceDetail;
  const serviceObject = {
    bookingQty1: 1,
    ticketType1: addServiceBookingResponse?.servicesBought?.priceType,
    ticketTotalPrice1: addServiceBookingResponse?.servicesBought?.totalPrice,
  };
  const eventDate = `${moment(addServiceBookingResponse.slot_date).format(
    "YYYY-MM-DD"
  )}`;
  const eventTime = `${addServiceBookingResponse.slot_start_time} - ${addServiceBookingResponse.slot_end_time}`;
  const data = {
    toName: userName,
    toEmail: userEmail,
    bookingType: "service",
    fromName: addServiceBookingResponse.businessName,
    notificationType,
    variables: {
      eventPhoto: reduxstate?.serviceData.servicePhoto,
      eventName: reduxstate?.serviceData.serviceName,
      eventDate: `${moment(
        addServiceBookingResponse.slot_date,
        "YYYY-MM-DD"
      ).format("dddd, MMMM Do")}`,
      eventTime: `${addServiceBookingResponse.slot_start_time} - ${addServiceBookingResponse.slot_end_time}`,
      eventLocation: reduxstate?.serviceData.venue_detail.address.addressName,
      eventAddress: reduxstate?.serviceData.venue_detail.address.address,
      // eventOrganizer: reduxstate.serviceData.teacher_detail.fullName,
      eventOrganizer: resourceDetail?.fullName,
      serviceFee: addServiceBookingResponse.servicesBought.serviceFee,
      totalPayableAmount: addServiceBookingResponse.amount,
      paymentMethod: addServiceBookingResponse.paymentMethod,
      bookingId: addServiceBookingResponse.bookingId,
      paymentStatus: addServiceBookingResponse.paymentStatus,
      manageBookingUrl: addServiceBookingResponse.manageBookingUrl,
      calendarURL: `https://devshop.bookzy.me/add-to-my-calender?eventName=${reduxstate?.serviceData.serviceName}&eventDate=${eventDate}&time=${eventTime}&eventLocation=${reduxstate?.serviceData.venue_detail.address.addressName}`,
      mapUrl:
        "https://www.google.com/maps/dir//" +
        reduxstate?.serviceData.venue_detail.address.address,
      duration: addServiceBookingResponse.servicesBought.duration,
      ...serviceObject,
    },
  };

  console.log("sendServiceConfirmationEmail-data", data);

  const config = {
    method: "post",
    url: BASE_URL + "/bookings/sendBookingConfirmation",
    headers: {
      "Content-Type": "application/json",
    },
    data,
  };

  const request = await baseQuery("sendServiceConfirmationEmail", config, {
    showErrorPopup: true,
  });

  return request;
};

export const checkTikkiePayStatus = async (body) => {
  console.log("checkTikkiePayStatus-dataa", body);
  const { paymentRequestToken } = body;

  const config = {
    method: "post",
    url: BASE_URL + "/bookings/payments/checkLinkPaymentStatus",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      paymentRequestToken,
    },
  };

  const request = await baseQuery("checkTikkiePayStatus", config, {
    showErrorPopup: true,
  });

  return request;
};

export const verifyOtp = async (body) => {
  console.log("verifyOtp-dataa", {
    code: body.code,
    token: body.phoneVerificationToken,
  });

  const config = {
    method: "post",
    url: BASE_URL + "/users/verifyOtpToAddParticipant",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      code: body.code,
      token: body.phoneVerificationToken,
    },
  };

  const request = await baseQuery("verifyOtp", config, {
    showErrorPopup: true,
  });

  return request;
};

// services
export const getAvailableSlots = async (body) => {
  const config = {
    method: "post",
    url: BASE_URL + "/bookings/getAvailableSlots",
    headers: {
      "Content-Type": "application/json",
      // "x-access-token": TEMP_TOKEN,
    },
    data: body,
  };

  const request = await baseQuery("getAvailableSlots", config, {
    showErrorPopup: false,
  });

  return request;
};

export const sendOtp = async (body) => {
  console.log("sendOtp-dataa", {
    serviceId: body.serviceData?._id,
    isPackage: false,
    username: `+${body.userInfo.username}`,
    fullName: body.userInfo.fullName,
  });

  const config = {
    method: "post",
    url: BASE_URL + "/users/sendOtpToAddParticipant",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      serviceId: body.serviceData?._id,
      isPackage: false,
      username: `+${body.userInfo.username}`,
      fullName: body.userInfo.fullName,
    },
  };

  const request = await baseQuery("sendOtp", config, { showErrorPopup: true });

  return request;
};

export const bookService = async (body) => {
  console.log("bookService-dataa", body);

  const config = {
    method: "post",
    url: BASE_URL + "/bookings/addServiceBookings",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": TEMP_TOKEN,
    },
    data: body,
  };

  const request = await baseQuery("bookService", config, {
    showErrorPopup: true,
  });

  return request;
};

export const addServiceBooking = async (body) => {
  console.log("addServiceBooking-dataa", body);

  const config = {
    method: "post",
    url: BASE_URL + "/bookings/addServiceBookings",
    data: body,
  };

  const request = await baseQuery("addServiceBooking", config, {
    showErrorPopup: true,
  });

  return request;
};

export const createServicePaymentLink = async (body) => {
  console.log("createServicePaymentLink-dataa", {
    ...body,
    isPackage: false,
  });

  const config = {
    method: "post",
    url: BASE_URL + "/bookings/payments/createPaymentLinkUA",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      ...body,
      isPackage: false,
    },
  };

  const request = await axios(config);

  console.log("createServicePaymentLink response", request.data);

  return request.data;
};

export const saveServiceReservations = async (body) => {
  console.log("saveServiceReservations-dataa", body);

  const config = {
    method: "post",
    url: BASE_URL + "/bookings/saveServiceReservations",
    headers: {
      "Content-Type": "application/json",
    },
    data: body,
  };

  const request = await baseQuery("saveServiceReservations", config, {
    showErrorPopup: true,
  });

  return request;
};

/**
 *
 * @param {{ bookingsReservationId: string, service_id: string, priceType: string, slot_date: string, slot_start_time: string, slot_end_time: string, duration: number }} body
 * @returns {Promise<[boolean, any]>} request
 */
export const updateServiceReservations = async (body) => {
  console.log("updateServiceReservations-dataa", body);

  const config = {
    method: "post",
    url: BASE_URL + "/bookings/updateServiceReservations",
    headers: {
      "Content-Type": "application/json",
    },
    data: body,
  };

  const request = await baseQuery("updateServiceReservations", config, {
    showErrorPopup: true,
  });

  return request;
};
