import React from "react";
import { useTranslation } from "react-i18next";

const ConfirmAlert = ({ onClose, message, title, onConfirm, onDeny }) => {
  const { t } = useTranslation();

  return (
    <div className="error-alert">
      <h1 className="title">{title}</h1>
      <p className="description">{message}</p>
      <div className="action-wrapper">
        <button
          className="action-deny"
          onClick={() => {
            onClose();
            onDeny();
          }}
        >
          {t("no")}
        </button>
        <button
          className="action"
          onClick={() => {
            onClose();
            onConfirm();
          }}
        >
          {t("yes")}
        </button>
      </div>
    </div>
  );
};

export default ConfirmAlert;
