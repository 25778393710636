import React from "react";
import { useTranslation } from "react-i18next";

const ErrorAlert = ({
  onClose,
  error,
  title,
  isServerError,
  actionAllowed = true,
}) => {
  const { t } = useTranslation();

  return (
    <div className="error-alert">
      <h1 className="title">
        {isServerError ? t("errors.server") : title || ""}
      </h1>
      <p className="description">{error}</p>
      {!actionAllowed ? null : (
        <button className="action" onClick={onClose}>
          {t("ok")}
        </button>
      )}
    </div>
  );
};

export default ErrorAlert;
