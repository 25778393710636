import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useAtom } from "jotai";
import { Flex, Text } from "@mantine/core";

import { Loader } from "components";
import BookingServiceConfirmation from "views/TicketServices/BookingServiceConfirmation";

import {
  SERVICE_ID,
  BOOKING_ID,
  BOOK_SERVICE_BODY,
  REDUX_STATE,
  SLOT_ID,
  SEND_EMAIL_CONFIRMATION_DATA,
} from "../../constants";
import { serviceStep } from "../../store/atoms";
import { updatestate } from "store/reducer";
import { useCheckTikkiePayStatusMutation } from "store/services/ticketServices";
import { useTranslation } from "react-i18next";

function ServicePayment() {
  const ticksID = window.localStorage.getItem("ticksID") || "";

  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const reduxstate = useSelector((state) => state.State);
  const { t } = useTranslation();

  const [, setSelectedStep] = useAtom(serviceStep);
  const [isLoading, setIsLoading] = useState(true);
  const [bookedServiceData, setBookedServiceData] = useState({});
  const [isBookingComplete, setIsBookingComplete] = useState(false);
  const [paymentSuccess, setPaymentSuccess] = useState(false);

  const [checkTikkiePayStatus] = useCheckTikkiePayStatusMutation();

  const fetchPaymentStatus = async () => {
    try {
      const { data, statusCode } = await checkTikkiePayStatus({
        paymentRequestToken: id,
      }).unwrap();

      if (statusCode === 200) {
        if (data?.paymentStatus === "Paid") {
          setIsLoading(false);
          const bookingPayload = data || {};

          // Clear localStorage and update Redux state
          dispatch(updatestate({ serviceBookingReservationId: null }));
          window.localStorage.removeItem(BOOK_SERVICE_BODY);
          window.localStorage.removeItem(BOOKING_ID);
          window.localStorage.removeItem(SERVICE_ID);
          window.localStorage.removeItem(SLOT_ID);

          setBookedServiceData(bookingPayload);
          setPaymentSuccess(true);
          setIsBookingComplete(true);
        } else {
          // Retry if payment is incomplete, with a delay
          setTimeout(fetchPaymentStatus, 3000);
        }
      } else {
        setIsLoading(false); // Stop loading if statusCode is not 200
      }
    } catch (error) {
      console.log("Error fetching payment status:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      (async () => {
        await fetchPaymentStatus();
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const onBack = () => {
    setSelectedStep(0);
    navigate(`/${ticksID}`, { replace: true });
  };

  useEffect(() => {
    window.onpopstate = () => {
      onBack();
      return;
    };
  }, []);

  console.log("BookedService Data", bookedServiceData);

  if (isLoading) {
    return (
      <Flex
        justify="center"
        align="center"
        direction="column"
        gap={12}
        className="ticket-view-loading"
      >
        <Loader size={40} />
        <Text fz="sm" fw={300}>
          {t("waiting-payment-confirmation")}
        </Text>
        <Flex justify="center" align="center" direction="column" gap={4}>
          <Text fz="xs" fw={400} color="gray">
            {t("tikkie-did-not-open")}
          </Text>
          <Text
            fz="xs"
            fw={400}
            underline
            color="blue"
            component="a"
            target="_blank"
            rel="noopener noreferrer"
            href={reduxstate.tikkiePayLink}
          >
            {t("click-here")}
          </Text>
        </Flex>
      </Flex>
    );
  }

  if (
    paymentSuccess &&
    isBookingComplete &&
    Object.keys(bookedServiceData).length > 0
  ) {
    return (
      <div className="ticket-services">
        <div className="wrapper">
          <BookingServiceConfirmation
            serviceData={reduxstate.serviceData}
            bookingConfirmationData={bookedServiceData}
            where={reduxstate?.serviceData?.venue_detail}
          />
        </div>
      </div>
    );
  }
}

export default ServicePayment;
