import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { confirmAlert } from "react-confirm-alert";
import { useNavigate } from "react-router-dom";
import { useAtom } from "jotai";
import { Button, Flex, Text, rem } from "@mantine/core";

import { BOOK_SERVICE_BODY, STRIPE_AC_ID, BOOKING_ID } from "constants";
import { step as eventStep, serviceStep } from "store/atoms";

import EventBuyingSummary from "views/TicketBooking/EventBuyingSummary";
import EventInfoCard from "views/TicketBooking/EventInfoCard";
import ServiceBuyingSummary from "views/TicketServices/ServiceBuyingSummary";
import ServiceInfoCard from "views/TicketServices/ServiceInfoCard";
import { Loader, ErrorAlert, UserDetails } from "components";
import { ArrowLeftIcon, CardIcon } from "assets/icon";
import { useCreateStripePaymentIntentMutation } from "store/services/ticketServices";
import { useTranslation } from "react-i18next";

function CardPayment({ clientSecret }) {
  const reduxstate = useSelector((state) => state.State);
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [, setServiceSelectedStep] = useAtom(serviceStep);
  const [, setEventSelectedStep] = useAtom(eventStep);

  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    if (clientSecret) {
      stripe.retrievePaymentIntent(clientSecret).then((res) => {
        switch (res.paymentIntent.status) {
          case "succeeded":
            // setMessage("Payment succeeded!");
            break;
          case "processing":
            // setMessage("Your payment is processing.");
            break;
          case "requires_payment_method":
            // setMessage("Your payment was not successful, please try again.");
            break;
          default:
            // setMessage("Something went wrong.");
            break;
        }
      });
    }
  }, [stripe, elements, clientSecret]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) return;

    setIsSubmitting(true);

    let return_url = process.env.REACT_APP_STRIPE_RETURN_URL_DEV;

    switch (true) {
      case process.env.REACT_APP_ENVIRONMENT === "PRE_STAGING":
        return_url = process.env.REACT_APP_STRIPE_RETURN_URL_PRE_STAGING;
        break;
      case process.env.REACT_APP_ENVIRONMENT === "STAGING":
        return_url = process.env.REACT_APP_STRIPE_RETURN_URL_STAGING;
        break;
      case process.env.REACT_APP_ENVIRONMENT === "PROD":
        return_url = process.env.REACT_APP_STRIPE_RETURN_URL_PROD;
        break;
      default:
        break;
    }

    const { error, paymentIntent } = await stripe.confirmCardPayment(
      clientSecret,
      {
        payment_method: {
          card: elements.getElement(CardElement),
        },
        return_url: `${return_url}/payment-check`,
      }
    );

    if (paymentIntent) {
      if (paymentIntent.status !== "succeeded") {
        confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <ErrorAlert
                error={t("errors.payment-fail")}
                isServerError={false}
                onClose={() => {
                  onClose();
                  if (reduxstate.currentDataFor === "ticket") {
                    const eventName = reduxstate.eventData.className
                      .replace(/\s+/g, "-")
                      .toLowerCase();
                    setEventSelectedStep(3);

                    navigate(`/booking/${eventName}`);
                  } else if (reduxstate.currentDataFor === "service") {
                    const serviceName = reduxstate.serviceData.serviceName
                      .replace(/\s+/g, "-")
                      .toLowerCase();
                    setServiceSelectedStep(4);

                    navigate(`/service/${serviceName}`);
                  }
                }}
              />
            );
          },
        });
        return;
      }

      if (paymentIntent.status === "succeeded") {
        const params = new URLSearchParams();
        params.append("payment_intent", paymentIntent.id);

        navigate(`/payment-check?${params.toString()}`, {
          replace: true,
        });
      }
    }

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.

    if (
      error &&
      (error.type === "card_error" || error.type === "validation_error")
    ) {
      setIsSubmitting(false);
      confirmAlert({
        customUI: ({ onClose }) => (
          <ErrorAlert
            onClose={onClose}
            error={error?.message}
            isServerError={false}
          />
        ),
        closeOnEscape: false,
        closeOnClickOutside: false,
      });
    } else if (error) {
      setIsSubmitting(false);
      confirmAlert({
        customUI: ({ onClose }) => (
          <ErrorAlert
            onClose={() => {
              onClose();
              navigate(-1);
            }}
            error={t("errors.server")}
            isServerError={false}
          />
        ),
        closeOnEscape: false,
        closeOnClickOutside: false,
      });
    }
  };

  return (
    <div className="stripe-payment">
      <div className="stripe-container">
        <form id="payment-form" onSubmit={handleSubmit}>
          <div className="stripe-form">
            <div className="form-container">
              <CardElement
                options={{
                  classes: {
                    base: "card-element-base",
                    focus: "card-element-focus",
                  },
                }}
              />
            </div>
          </div>
          <Button
            fullWidth
            radius={10}
            bg="#f64e60"
            size="lg"
            fz="md"
            disabled={isSubmitting || !stripe || !elements}
            id="submit"
            sx={{
              opacity: isSubmitting || !stripe || !elements ? 0.5 : 1,
              "&:hover": {
                backgroundColor: "#f64e60ee",
                transition: "background-color 0.2s ease-in-out",
              },
            }}
            loading={isSubmitting}
            type="submit"
          >
            {t("pay-and-confirm")}
          </Button>
        </form>
      </div>
    </div>
  );
}

export default function ElementsWrapper() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [stripePromise, setStripePromise] = useState(null);
  const [clientSecret, setClientSecret] = useState();
  const [, setServiceStep] = useAtom(serviceStep);
  const [, setEventStep] = useAtom(eventStep);

  const reduxstate = useSelector((state) => state.State);
  const stripeAcId = window.localStorage.getItem(STRIPE_AC_ID);
  let bookServiceBody = window.localStorage.getItem(BOOK_SERVICE_BODY) || {};
  bookServiceBody = Object.keys(bookServiceBody).length
    ? JSON.parse(bookServiceBody)
    : {};

  const [createPaymentIntent] = useCreateStripePaymentIntentMutation();

  useEffect(() => {
    const res = loadStripe(
      process.env.REACT_APP_ENVIRONMENT === "PROD"
        ? process.env.REACT_APP_STRIPE_LIVE_KEY
        : process.env.REACT_APP_STRIPE_TEST_KEY,
      stripeAcId ? { stripeAccount: stripeAcId } : undefined
    );

    setStripePromise(res);
  }, [stripeAcId]);

  useEffect(() => {
    (async () => {
      const body = {
        serviceId: bookServiceBody.service_id,
        slotDuration: bookServiceBody.duration,
        priceType: bookServiceBody.priceType,
        slotPrice: Number(reduxstate.slotData?.price),
        slotDescription:
          reduxstate.slotData?.description ||
          reduxstate.serviceData.serviceName,
      };

      const serviceBookingPayload = {
        ...bookServiceBody,
        bookingId: "",
        utcOffset: new Date().getTimezoneOffset(),
      };

      const response = await createPaymentIntent({
        amount: Number(reduxstate.totalPrice),
        paymentMethod: "creditCard",
        currency: "eur",
        isPackage: false,
        serviceBookingPayload,
        ...body,
      }).unwrap();

      const { client_secret, referenceId } = response?.data;

      window.localStorage.setItem(BOOKING_ID, referenceId);
      setClientSecret(client_secret);
    })();
  }, []);

  const handleChangePaymentMethod = () => {
    if (reduxstate.currentDataFor === "service") {
      setServiceStep(4);
      navigate(-1, { replace: true });
    } else {
      setEventStep(3);
      navigate(-1, { replace: true });
    }
  };

  if (!stripePromise) return null;

  return (
    <div>
      {clientSecret ? (
        <Elements stripe={stripePromise} options={{ clientSecret }}>
          <div className="ticket-services">
            <div className="wrapper">
              <div className="content">
                <div className="page-hdr">
                  <button className="back-btn" onClick={() => navigate(-1)}>
                    <ArrowLeftIcon />
                  </button>
                  <div className="title">{t("review-and-pay")}</div>
                </div>
                {reduxstate.currentDataFor === "service" ? (
                  <>
                    <ServiceInfoCard
                      serviceData={reduxstate.serviceData}
                      resource={reduxstate.resourceDetail}
                      isReadMore={false}
                      selectedStep={5}
                      toggleReadMore={() => {}}
                      when={{
                        day: bookServiceBody.slot_date,
                        start: bookServiceBody.slot_start_time,
                        end: bookServiceBody.slot_end_time,
                      }}
                      where={reduxstate.serviceSelectedLocation}
                      slotData={reduxstate.slotData}
                    />
                    <UserDetails />
                    <ServiceBuyingSummary reduxstate={reduxstate} />
                  </>
                ) : (
                  <>
                    <EventInfoCard
                      reduxstate={reduxstate}
                      selectedStep={5}
                      isReadMore={false}
                      toggleReadMore={() => {}}
                    />
                    <UserDetails />
                    <EventBuyingSummary reduxstate={reduxstate} />
                  </>
                )}
                <div className="section">
                  <div className="block">
                    <Flex
                      direction="row"
                      justify="space-between"
                      align="center"
                    >
                      <Flex direction="row" gap="sm" align="center">
                        <CardIcon w={rem(32)} h={rem(32)} />
                        <Text fw={700} fz="lg">
                          {t("card")} {t("pay")}
                        </Text>
                      </Flex>
                      <Button
                        variant="subtle"
                        onClick={handleChangePaymentMethod}
                        fz="md"
                        c="#584CF4"
                      >
                        {t("change")}
                      </Button>
                    </Flex>
                  </div>
                </div>
              </div>
            </div>
            <CardPayment clientSecret={clientSecret} />
          </div>
        </Elements>
      ) : (
        <div className="ticket-view-loading">
          <Loader size={30} thin={2} />
        </div>
      )}
    </div>
  );
}
